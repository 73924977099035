import React, { useState } from 'react';
import axios from 'axios';

const CallbackForm = ({ contactName, onCancel }) => {
  const [countryCode, setCountryCode] = useState('+31');
  const [phoneNumber, setPhoneNumber] = useState('');

  // Hardcode API base URL
  const apiBaseUrl = "https://mc-clean-portal-backend.azurewebsites.net/api/send-telegram";

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); 
    setPhoneNumber(value);
  };

  const handleCallbackSubmit = async (e) => {
    e.preventDefault();
    let correctedNumber = phoneNumber;
    if (phoneNumber.length === 10 && phoneNumber.startsWith('0')) {
      correctedNumber = phoneNumber.substring(1); 
    }

    if (correctedNumber.length < 9 || correctedNumber.length > 10) {
      alert('Voer een geldig telefoonnummer in met 9 of 10 cijfers.');
      return;
    }

    const fullNumber = `${countryCode}${correctedNumber}`;
    if (!window.confirm(`Wij zullen u terugbellen op dit nummer: ${fullNumber}\n\nAls dit klopt, druk op OK!`)) {
      return;
    }

    try {
      const response = await axios.post(`${apiBaseUrl}`, {
        message: `Terugbelverzoek ontvangen voor ${fullNumber}. Gesproken met: ${contactName}`
      });
      if (response.status === 200) {
        alert('Verzoek tot terugbellen succesvol verzonden.');
      } else {
        alert('Er is iets misgegaan bij het verzenden van het verzoek.');
      }
    } catch (error) {
      alert('Er is een fout opgetreden: ' + error.message);
    }

    setPhoneNumber('');
    setCountryCode('+31');
    onCancel();
  };

  return (
    <form className="callback-form" onSubmit={handleCallbackSubmit}>
      <div className="input-group">
        <select
          className="country-code"
          value={countryCode}
          onChange={(e) => setCountryCode(e.target.value)}
          required
        >
          <option value="+31">NL (+31)</option>
          <option value="+32">BE (+32)</option>
          <option value="+49">DE (+49)</option>
          <option value="+352">LU (+352)</option>
        </select>
        <input
          type="tel"
          placeholder="Voer uw telefoonnummer in"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          required
        />
      </div>
      <div className="button-group">
        <button type="button" className="button back-button" onClick={onCancel}>Terug</button>
        <button type="submit" className="button">Verzend</button>
      </div>
    </form>
  );
};

export default CallbackForm;
