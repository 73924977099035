import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PromoHeader from '../components/PromoHeader';
import PhotoUpload from './PhotoUpload';
import './compo_styles/FormStyles.css';

const OfferteForm = ({ onCancel }) => {
  const [name, setName] = useState('');
  const [postcode, setPostcode] = useState('');
  const [huisnummer, setHuisnummer] = useState('');
  const [adres, setAdres] = useState('');
  const [countryCode, setCountryCode] = useState('+31');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [photos, setPhotos] = useState([]);
  const [contactPreference, setContactPreference] = useState('');
  const [loading, setLoading] = useState(false);
  const [showExtraMessage, setShowExtraMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    let timer;
    if (loading) {
      timer = setTimeout(() => {
        setShowExtraMessage(true);
      }, 4000);
    } else {
      setShowExtraMessage(false);
    }
    return () => clearTimeout(timer);
  }, [loading]);

  const sasUrl = 'https://mccleaning.blob.core.windows.net/publicupload';
  const sasToken = 'sp=acw&st=2024-07-23T13:47:01Z&se=2025-07-23T21:47:01Z&spr=https&sv=2022-11-02&sr=c&sig=5hXzlJXO66B5GjM7jaza2pzefv2K%2F0KNqCQO0NmMKQg%3D';

  const handlePhoneNumberChange = (e) => {
    const value = e.target.value.replace(/\D/g, '');
    if (value.length <= 10) {
      setPhoneNumber(value);
    }
  };

  const handlePostcodeChange = (e) => {
    const value = e.target.value.replace(/\s+/g, '').toUpperCase();
    if (/^\d{4}[A-Z]{2}$/.test(value)) {
      setPostcode(value.slice(0, 4) + ' ' + value.slice(4));
    } else {
      setPostcode(value);
    }
  };

  // Uploadfunctie gebruikt nu het File-object uit photo.compressedFile of photo.originalFile
  // en stelt de URL samen. Als de container publiek toegankelijk is, kun je de SAS-token verwijderen.
  const uploadPhotosToAzure = async (photos) => {
    let uploadedFiles = [];
    for (let photo of photos) {
      const fileToUpload = photo.compressedFile || photo.originalFile;
      const photoName = fileToUpload.name;
      if (!photoName) {
        console.error('Photo name is undefined:', photo);
        continue;
      }
      const blobUrl = `${sasUrl}/${encodeURIComponent(photoName)}?${sasToken}`;
      try {
        const response = await axios.put(blobUrl, fileToUpload, {
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'Content-Type': fileToUpload.type,
          },
        });
        if (response.status === 201) {
          // Indien je container publiek toegankelijk is, gebruik dan de URL zonder querystring:
          const fileUrl = blobUrl.split('?')[0];
          uploadedFiles.push(fileUrl);
          console.log(`Foto succesvol geüpload: ${fileUrl}`);
        } else {
          throw new Error(`Upload mislukt met statuscode: ${response.status}`);
        }
      } catch (error) {
        console.error('Fout bij het uploaden van foto:', error);
        alert('Fout bij het uploaden van foto: ' + error.message);
      }
    }
    return uploadedFiles;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSuccessMessage('');

    if (phoneNumber.length < 9 || phoneNumber.length > 10) {
      alert('Een correct telefoonnummer mag 9 of 10 cijfers bevatten.');
      setLoading(false);
      return;
    }

    const formattedPhoneNumber = phoneNumber.startsWith('0')
      ? phoneNumber.substring(1)
      : phoneNumber;

    try {
      let photoUrls = [];
      if (photos.length > 0) {
        photoUrls = await uploadPhotosToAzure(photos);
      }

      const jsonData = {
        name,
        postcode,
        huisnummer,
        adres,
        countryCode,
        phoneNumber: `${countryCode}${formattedPhoneNumber}`,
        email,
        contactPreference,
        photoUrls, // Dit bevat de URL's (zonder SAS-token als je container publiek is)
      };

      const response = await axios.post(
        'https://mc-clean-portal-backend.azurewebsites.net/api/send-telegram-quote',
        jsonData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status !== 200 && response.status !== 201) {
        throw new Error('Fout bij het verzenden van gegevens');
      }

      setSuccessMessage('Uw aanvraag is succesvol verzonden, bedankt!');
    } catch (error) {
      console.error('Fout bij het verzenden:', error);
      alert('Er is een fout opgetreden: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader-container">
          <svg
            version="1.1"
            id="L9"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 100"
            enableBackground="new 0 0 0 0"
            xmlSpace="preserve"
          >
            <path
              fill="#0000ff"
              d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
            >
              <animateTransform
                attributeName="transform"
                attributeType="XML"
                type="rotate"
                dur="1s"
                from="0 50 50"
                to="360 50 50"
                repeatCount="indefinite"
              />
            </path>
          </svg>
          <p>Een moment geduld aub.</p>
          {showExtraMessage && (
            <p>
              Uw aanvraag wordt ingediend, bijna klaar. <br />
              <br />
              Sluit dit venster niet.
            </p>
          )}
        </div>
      ) : successMessage ? (
        <div className="success-message">
          <p>{successMessage}</p>
          <p>
            Wij nemen zo snel mogelijk contact met u op.
            <br />
            U kunt dit venster sluiten of op "Ga terug" drukken om terug te keren.
          </p>
          <button onClick={() => window.location.reload()} className="button">
            Ga terug
          </button>
        </div>
      ) : (
        <>
          <PromoHeader contactName="PromoCard" />
          <form className="offerte-form" onSubmit={handleSubmit}>
            <div className="input-group">
              <input
                type="text"
                placeholder="Naam"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Postcode"
                value={postcode}
                onChange={handlePostcodeChange}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Huisnummer"
                value={huisnummer}
                onChange={(e) => setHuisnummer(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="text"
                placeholder="Adres"
                value={adres}
                onChange={(e) => setAdres(e.target.value)}
                required
              />
            </div>
            <div className="input-group phone-number-group">
              <select
                className="country-code"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                required
              >
                <option value="+31">NL (+31)</option>
                <option value="+32">BE (+32)</option>
                <option value="+49">DE (+49)</option>
                <option value="+352">LU (+352)</option>
              </select>
              <input
                type="tel"
                placeholder="Voer uw telefoonnummer in"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                required
              />
            </div>
            <div className="input-group">
              <input
                type="email"
                placeholder="E-mailadres"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-group">
              <select
                value={contactPreference}
                onChange={(e) => setContactPreference(e.target.value)}
                required
              >
                <option value="">Selecteer een contactvoorkeur</option>
                <option value="phone">Ik wil graag gebeld worden</option>
                <option value="whatsapp">Ik wil graag contact via WhatsApp</option>
                <option value="noPreference">Geen voorkeur</option>
              </select>
            </div>
            <div className="input-group">
              <p>Optioneel: voeg 1 tot 4 foto's toe</p>
              <PhotoUpload photos={photos} setPhotos={setPhotos} />
            </div>
            <div className="button-group">
              <button type="button" className="button back-button" onClick={onCancel}>
                Terug
              </button>
              <button type="submit" className="button">
                Verzend
              </button>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

export default OfferteForm;
