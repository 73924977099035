// src/components/PhotoUpload.js

import React, { useState } from 'react';
import imageCompression from 'browser-image-compression';
import './compo_styles/PhotoUpload.css';

const PhotoUpload = ({ photos, setPhotos }) => {
  const [compressing, setCompressing] = useState(false);

  const handleFileChange = async (e) => {
    const files = Array.from(e.target.files);
    const totalPhotos = files.length + photos.length;

    if (totalPhotos > 4) {
      alert('U kunt maximaal 4 foto\'s toevoegen.');
      return;
    }

    setCompressing(true);

    const compressedPhotos = await Promise.all(
      files.map(async (file) => {
        if (file.size > 1 * 1024 * 1024) {
          try {
            const options = {
              maxSizeMB: 2.2,
              maxWidthOrHeight: 1000,
              useWebWorker: true,
              initialQuality: 0.7,
            };

            const compressedFile = await imageCompression(file, options);
            // Maak een nieuw bestand met de originele naam
            const renamedCompressedFile = new File(
              [compressedFile],
              file.name,
              { type: compressedFile.type, lastModified: compressedFile.lastModified }
            );
            return {
              originalFile: file,
              compressedFile: renamedCompressedFile,
            };
          } catch (error) {
            console.error('Fout bij het comprimeren van foto:', error);
            alert('Fout bij het comprimeren van foto: ' + error.message);
            return null;
          }
        } else {
          // Gebruik het originele bestand zonder compressie, maar maak een nieuw File-object
          const renamedFile = new File(
            [file],
            file.name,
            { type: file.type, lastModified: file.lastModified }
          );
          return {
            originalFile: file,
            compressedFile: renamedFile,
          };
        }
      })
    );

    const validPhotos = compressedPhotos.filter(Boolean);
    setPhotos((prevPhotos) => [...prevPhotos, ...validPhotos]);
    setCompressing(false);
  };

  const handleRemovePhoto = (index) => {
    setPhotos((prevPhotos) => prevPhotos.filter((_, i) => i !== index));
  };

  return (
    <div className="photo-upload">
      <input
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        style={{ display: 'none' }}
        id="photo-upload-input"
      />
      <label htmlFor="photo-upload-input" className="photo-upload-label">
        Klik om bestanden toe te voegen
      </label>
      {compressing && <p>Bezig met comprimeren van foto's, een moment geduld...</p>}
      <div className="thumbnails">
        {photos.map((photo, index) => (
          <div key={index} className="thumbnail">
            <img
              src={URL.createObjectURL(photo.originalFile)}
              alt={`Foto ${index + 1}`}
            />
            <button
              type="button"
              className="remove-button"
              onClick={() => handleRemovePhoto(index)}
            >
              Verwijderen
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoUpload;
